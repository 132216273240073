import store from '@/store'

export default function useCallPreviewAudioPlayer() {
  let currentAudio = null
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()

  const endPlayback = () => {
    if (currentAudio) {
      currentAudio.stop()
      currentAudio.disconnect()
      currentAudio = null
    }
    store.commit('setActiveCall', false)
  }

  // Function to decode and concatenate multiple audio files with a delay at the end
  const playAudiosSequentially = async (audioConfigs, endDelay = 2) => {
    if (store.state.activeCall) {
      return
    }

    // endDelay in seconds
    if (audioConfigs.length === 0) {
      store.commit('setActiveCall', false)

      return
    }

    try {
      // Decode each audio file and concatenate them
      const audioBuffers = await Promise.all(
        audioConfigs.map(async base64Audio => {
          const audioData = atob(base64Audio)
          const audioArrayBuffer = new Uint8Array([...audioData].map(char => char.charCodeAt(0))).buffer

          return audioContext.decodeAudioData(audioArrayBuffer)
        }),
      )

      // Calculate the total duration of the concatenated audio plus end delay
      const totalDuration = audioBuffers.reduce((sum, buffer) => sum + buffer.duration, 0) + endDelay

      // Create an empty AudioBuffer to hold the combined audio
      const combinedBuffer = audioContext.createBuffer(
        audioBuffers[0].numberOfChannels,
        audioContext.sampleRate * totalDuration,
        audioContext.sampleRate,
      )

      // Copy each buffer into the combined buffer sequentially
      let offset = 0
      audioBuffers.forEach(buffer => {
        for (let channel = 0; channel < combinedBuffer.numberOfChannels; channel++) {
          combinedBuffer.getChannelData(channel).set(buffer.getChannelData(channel), offset)
        }
        offset += buffer.length
      })

      // Add silence at the end
      offset += audioContext.sampleRate * endDelay // extend offset by delay duration in samples

      // Play the combined audio buffer with end delay
      currentAudio = audioContext.createBufferSource()
      currentAudio.buffer = combinedBuffer
      currentAudio.connect(audioContext.destination)
      currentAudio.start()

      store.commit('setActiveCall', true)

      // Reset playback when the audio ends
      currentAudio.onended = () => {
        endPlayback()
      }
    } catch (error) {
      console.error('Error combining audio files:', error)
      store.commit('setActiveCall', false)
    }
  }

  return {
    playAudiosSequentially,
    endPlayback,
  }
}
