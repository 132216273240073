<template>
  <!-- Iphone display  -->
  <v-container class="d-flex justify-center">
    <v-col
      cols="12"
      md="3"
      sm="4"
      class="d-flex justify-center"
    >
      <div class="mx-auto">
        <device
          v-bind="$data"
        >
          <v-container
            class="iphoneSettings"
          >
            <div
              dir="ltr"
              style="justify-content: center;text-align-last: center;margin-top: 30px;"
              class="black--text font-weight-bold text-xl"
            >
              Anita-lee
            </div>
            <div v-if="!$store.state.activeCall">
              <div
                dir="auto"
                style="justify-content: center;text-align-last: center;margin-top: 2px;"
                class="black--text "
              >
                {{ $t('Calling') }}
              </div>
              <v-row
                style="margin-top: 125px"
                align="center"
                justify="center"
              >
              <div class="d-flex">
                <v-card
                  color="transparent"
                  outlined
                  flat
                >
                  <v-card-title class="align-center justify-center">
                    <v-btn
                      icon
                      class="acceptCall animationBtn"
                      color="white"
                      @click="playAudio(message)"
                    >
                      <v-icon>
                        {{ icons.mdiPhone }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle class="text-xs text-center">
                    {{ $t('Accept') }}
                  </v-card-subtitle>
                </v-card>
                <v-card
                  color="transparent"
                  outlined
                  flat
                >
                  <v-card-title class="align-center justify-center">
                    <v-btn
                      icon
                      class="declineCall"
                      color="white"
                    >
                      <v-icon>
                        {{ icons.mdiPhoneHangup }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle class="text-xs text-center">
                    {{ $t('Decline') }}
                  </v-card-subtitle>
                </v-card>
                </div>
              </v-row>
            </div>
            <div v-if="$store.state.activeCall">
              <StopWatch @endCall="endPlayback"></StopWatch>
              <v-row
                style="margin-top: 145px"
                align="center"
                justify="center"
              >
                <v-card
                  color="transparent"
                  outlined
                  flat
                >
                  <v-card-title class="align-center justify-center">
                    <v-btn
                      icon
                      class="declineCall"
                      color="white"
                      @click="endPlayback"
                    >
                      <v-icon>
                        {{ icons.mdiPhoneHangup }}
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                </v-card>
              </v-row>
            </div>
          </v-container>
        </device>
      </div>
    </v-col>
  </v-container>
</template>
<script>
import StopWatch from '@/components/NotificationTemplates/StopWatch.vue'
import useCallPreviewAudioPlayer from '@/composables/useCallPreviewAudioPlayer'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiPhone, mdiPhoneHangup } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import device from 'vue-and-devices-mocks'
import 'vue-push-notification-preview/src/assets/devices.scss'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    device,
    StopWatch,
  },
  props: ['message', 'audioPlayer'],
  data() {
    return {
      type: 'iphone',
      scale: 12,
    }
  },
  setup(props) {
    const { playAudiosSequentially, endPlayback } = props.audioPlayer || useCallPreviewAudioPlayer()
    const loading = ref(true)
    const toast = useToast()
    const showToast = msg => toast.error(i18n.t(msg))

    onUnmounted(() => {
      endPlayback()
    })

    const playAudio = msg => {
      loading.value = true
      if (msg.message.length < 20) {
        loading.value = false
        showToast(i18n.t('MinField', { num: '20' }))
      } else {
        store
          .dispatch('textToSpeech', {
            id: store.state.eventData.id,
            callTemplate: { lang: msg.lang, text: msg.message },
          })
          .then(res => {
            loading.value = false
            playAudiosSequentially(res.data)
          })
          .catch(() => {
            endPlayback()
            showToast('errorManageEvents')
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    return {
      playAudio,
      loading,
      endPlayback,

      icons: {
        mdiPhone,
        mdiPhoneHangup,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.acceptCall {
  background: #7fff00 !important;
}
.declineCall {
  background: #ff2400 !important;
}
.iphoneSettings {
  background-image: url('~@/assets/images/misc/knowledge-base-bg-light.png') !important;
  background: top;
  display: block;
  margin-top: 0px;
  height: 100%;
}
.animationBtn {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(0px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}
.volumeIcon {
  animation: zoomIn 2s both infinite;
}
@keyframes zoomIn {
  from {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
</style>
