<template>
  <div
    class="time text-center"
  >
    {{ time }}
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  setup(props, { emit }) {
    const timeBegan = ref(null)
    const time = ref('00:00')
    const timeID = ref(null)

    const stopWatch = () => {
      const currentTime = new Date()
      const timeElapsed = new Date(currentTime - timeBegan.value)
      let min = timeElapsed.getUTCMinutes()
      let sec = timeElapsed.getUTCSeconds()
      min = min < 10 ? `0${min}` : min
      sec = sec < 10 ? `0${sec}` : sec
      time.value = `${min}:${sec}`

      // End the call if it lasts more than 5 minutes
      if (min > 4) {
        emit('endCall', timeID.value)
      }
    }
    const startTimer = () => {
      timeID.value = setInterval(stopWatch)
    }
    timeBegan.value = new Date()
    startTimer()

    return {
      timeBegan,
      time,
      startTimer,
    }
  },
}
</script>
